import axios from './request'
import {businessApi, sign} from "./api";
import common from "./common";
//采购信息列表
export var goodsList = (params) => {
    return axios({
        url: `${businessApi}/purchase/goodsList`,
        method: 'POST',
        data: sign(params)
    })
}
export var deletePurchase = (params) => {
    return axios({
        url: `${businessApi}/purchase/deletePurchase`,
        method: 'POST',
        data: sign(params)
    })
}
export var putInfoItem = (params) => {
    return axios({
        url: `${businessApi}/report/putInfoItem`,
        method: 'POST',
        data: sign(params)
    })
}
export var reimburse = (params) => {
    return axios({
        url: `${businessApi}/report/reimburse`,
        method: 'POST',
        data: sign(params)
    })
}
export var getReimburseInfo = (params) => {
    return axios({
        url: `${businessApi}/report/getReimburseInfo`,
        method: 'POST',
        data: sign(params)
    })
}
//获取所有物品类别信息
export var getAllItem = (params) => {
    return axios({
        url: `${businessApi}/goods/getAllItem`,
        method: 'POST',
        data: sign(params)
    })
}
//采购信息保存
export var add = (params) => {
    return axios({
        url: `${businessApi}/purchase/add`,
        method: 'POST',
        data: sign(params)
    })
}
//获取种类信息
export var getParentItem = (params) => {
    return axios({
        url: `${businessApi}/goods/getParentItem`,
        method: 'POST',
        data: sign(params)
    })
}
//获取子类信息
export var getItemByPItemCode = (params) => {
    return axios({
        url: `${businessApi}/goods/getItemByPItemCode`,
        method: 'POST',
        data: sign(params)
    })
}
//调拨列表
export var applyList = (params) => {
    return axios({
        url: `${businessApi}/transfer/applyList`,
        method: 'POST',
        data: sign(params)
    })
}
//库存信息列表
export var stockList = (params) => {
    return axios({
        url: `${businessApi}/inStock/goodList`,
        method: 'POST',
        data: sign(params)
    })
}
//库存调拨
export var transfer = (params) => {
    return axios({
        url: `${businessApi}/inStock/transfer`,
        method: 'POST',
        data: sign(params)
    })
}
//出库撤销
export var cancelJump = (params) => {
    return axios({
        url: `${businessApi}/outStock/cancelJump`,
        method: 'POST',
        data: sign(params)
    })
}
//站点销售和库存查询
export var getStationSellAndStockInfo = (params) => {
    var token = '';
    if (common.getItem('wtToken')){
        token =  common.getItem('wtToken')
    }
    return axios({
        url: `${businessApi}/outStock/getStationSellAndStockInfo/?stationNo=${params}&token=${token}`,
        method: 'GET'
    })
}
//调拨申请确认
export var applyConfirm = (params) => {
    return axios({
        url: `${businessApi}/transfer/applyConfirm`,
        method: 'POST',
        data: sign(params)
    })
}
//使用出库列表
export var outStockList = (params) => {
    return axios({
        url: `${businessApi}/outStock/outStockList`,
        method: 'POST',
        data: sign(params)
    })
}
//手动出库 站点名称列表
export var selectStation = (params) => {
    return axios({
        url: `${businessApi}/outStock/selectStation`,
        method: 'POST',
        data: sign(params)
    })
}
//手工出库-设备列表
export var equipmentList = (params) => {
    return axios({
        url: `${businessApi}/outStock/equipmentList`,
        method: 'POST',
        data: sign(params)
    })
}
//手工出库-出库
export var manualOut = (params) => {
    return axios({
        url: `${businessApi}/outStock/manualOut`,
        method: 'POST',
        data: sign(params)
    })
}
//手工出库-设备列表（非站点）
export var selectStockInfoForOtherPut = (params) => {
    return axios({
        url: `${businessApi}/outStock/selectStockInfoForOtherPut`,
        method: 'POST',
        data: sign(params)
    })
}

//手工出库-出库（非站点）
export var manualOutNoStation = (params) => {
    return axios({
        url: `${businessApi}/outStock/manualOutNoStation`,
        method: 'POST',
        data: sign(params)
    })
}

//报备-查询
export var reportList = (params) => {
    return axios({
        url: `${businessApi}/report/reportList`,
        method: 'POST',
        data: sign(params)
    })
}
