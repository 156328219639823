<template>
    <div class="putInto">
        <div class="flex search-box">
            <!--<van-cell title="站点名称" :value="stationTxt" @click="showkindList=true"/>-->
            <van-field v-model="stationTxt" label="站点名称" placeholder="请输入关键字" />
            <van-button type="primary" size="small" color="#3F7C53" @click="searchKeyword">搜 索</van-button>

        </div>
        <van-popup v-model="showkindList" round position="bottom">
            <van-picker title="选择名称" show-toolbar
                        :columns="kindList"
                        @cancel="showkindList = false"
                        @confirm="onConfirmName"
            />
        </van-popup>
        <div v-for="(item,idx) in equlist" :key="idx">
            <van-field :label="item.itemName + '-' + item.unitPrice + '(元) *' + item.quantity"
                       label-width="60%"
                       v-model="item.num"
                       placeholder="请输入"
                       type="number"
                       :formatter="formatter"/>
        </div>
        <div class="imgUpload" >
            <div class="imgTitleDiv">
                <p class="lineTitle">请上传站长和物品的水印照片合影,广告物料无需上传</p>
            </div>
            <div class="imgDiv">
                <van-uploader v-model="imgArr" accept="image" :max-count="2"  :after-read="afterRead" :before-delete="deletePic"/>
            </div>

        </div>
        <div class="button-one">
            <van-button type="primary" color="#3F7C53" @click="affirm">确 定</van-button>
        </div>

    </div>

</template>

<script>
    import common from '@/utils/common'
    import {selectStation,equipmentList,manualOut} from '@/utils/putInto';
    import {Toast} from "vant";
    import JsZip from "jszip";
    import {uploadZipAndAlreadyImg} from "../../utils/api";
    export default {
        name: "facilityRemoval.vue",
        data(){
            return{
                showkindList:false,
                kindList:[],
                stationTxt:'',
                subData: {
                    stationNo:'',//站点编号
                    imgPath:''
                },
                equlist:[],
                imgArr:[],
                uploadZipImgList:[]


            }
        },
        created(){
            // this.getStationList();
            this.getEquipment();
        },
        methods:{
            formatter(value) {
                // 过滤输入的数字
                return value.replace(/\./, '');
            },
            getStationList(name){
                selectStation({stationName:name}).then(res=>{
                    if(res.code ===200 && res.data.businessCode === 200){
                        this.showkindList = true;
                        this.kindList = res.data.data.map(item=>{
                            item.text = item.stationNameAndMasterName;
                            delete item.stationNameAndMasterName;
                            return item;
                        })
                        this.kindList.unshift({text:'请选择',stationNo:''});
                    }
                })
            },
            //根据关键字查询站点名称
            searchKeyword(){
                this.getStationList(this.stationTxt);
            },
            getEquipment(){
                equipmentList({}).then(res=>{
                    if(res.code ===200 && res.data.businessCode === 200){
                        this.equlist= res.data.data;
                    }

                })
            },
            onConfirmName(value){
                this.stationTxt = value.text==='请选择'?'':value.text;
                this.subData.stationNo = value.stationNo;
                this.showkindList = false;
            },
            affirm(){
                if(!this.subData.stationNo){
                    this.$message.warning('请选择站点名称');
                    return;
                }
                let arr = this.equlist;
                let putIntoArr = [];
                let needPhoto='0';
                for(let i = 0; i < arr.length; i++){
                    console.log("arr",arr[i])
                    if(arr[i].num){
                        if(arr[i].num==='0'){
                            Toast('数量必须大于0 !');
                            return;
                        }
                        putIntoArr.push(arr[i]);
                        //如果存在非广告物料类，则需上传照片
                        console.log("---",arr[i].pid)
                        if(arr[i].pid!=='7'){
                            needPhoto='1';
                        }
                    }
                }
                if(putIntoArr.length === 0 ){
                    Toast('请输入设备配置数量!');
                    return;
                }
                if(needPhoto=='1'&&this.uploadZipImgList.length < 1 ){
                    Toast('请上传1-2张站长和物品的水印照片合影')
                    return
                }
                if(this.uploadZipImgList.length < 1 ){
                    let data = {
                        outItemList:putIntoArr,
                        ...this.subData
                    }
                    this.$dialog.alert({
                        message: '请确认对这些物品出库？',
                        showCancelButton: true,
                        confirmButtonColor:'#3F7C53'
                    }).then(() => {
                        manualOut(data).then(res=>{
                            console.log('确认按钮', res);
                            if(res.code ===200 && res.data.businessCode === 200){
                                this.$message.success('出库成功!');
                                this.$router.go(-1);
                                common.setItem('active', 4)
                            }
                        })
                    })
                    return;
                }
                let self = this


                var zip = new JsZip();
                var img = zip.folder("images");
                for (var i=0;i<this.uploadZipImgList.length;i++){
                    img.file("facilityPic_"+ i+this.uploadZipImgList[i].name, this.uploadZipImgList[i].data.substring(this.uploadZipImgList[i].data.indexOf(",")+1), {base64: true});
                }
                let json = {imgName: self.fileImgUrls}
                let fileAleradyJson = JSON.stringify(json)
                zip.generateAsync({type:"blob"}).then(function(content) {
                    console.log("上传图片到服务器")
                    uploadZipAndAlreadyImg(content,self.subData.stationNo,fileAleradyJson).then(res=>{
                        console.log("res", res)
                        if (res.code == 200){
                            if (res.data.businessCode && res.data.businessCode === 200) {
                                self.subData.imgPath =JSON.stringify(res.data.facilityPic)
                                let data = {
                                    outItemList:putIntoArr,
                                    ...self.subData
                                }
                                self.$dialog.alert({
                                    message: '请确认对这些物品出库？',
                                    showCancelButton: true,
                                    confirmButtonColor:'#3F7C53'
                                }).then(() => {
                                    manualOut(data).then(res=>{
                                        console.log('确认按钮', res);
                                        if(res.code ===200 && res.data.businessCode === 200){
                                            self.$message.success('出库成功!');
                                            self.$router.go(-1);
                                            common.setItem('active', 4)
                                        }
                                    })
                                })
                                    .catch(() => {
                                    })
                            }
                        }
                    }).catch(()=>{
                    })

                },e=>{
                    this.$toast('上传图片异常')

                });

            },
            /*
            affirm(){

                let data = {
                    outItemList:putIntoArr,
                    ...this.subData
                }
                this.$dialog.alert({
                    message: '请确认对这些物品出库？',
                    showCancelButton: true,
                    confirmButtonColor:'#3F7C53'
                }).then(() => {
                    manualOut(data).then(res=>{
                        console.log('确认按钮', res);
                        if(res.code ===200 && res.data.businessCode === 200){
                            this.$message.success('出库成功!');
                            this.$router.go(-1);
                            common.setItem('active', 4)
                        }
                    })
                })
                .catch(() => {
                })
            },
             */
            afterRead(file, detail) {
                // 大于15M
                if(file.file.size > 15 * 1024 * 1024){
                    Toast('图片太大，请上传小于15M的图片')
                    return
                }
                if(!common.imageVerify(file.file.type)){
                    this.fileList.splice(detail.index, 1)
                    Toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                let _this = this
                if(file.file.size < 100 * 1024){
                    console.log("小图片")
                    let namearr = file.file.name.split('.')
                    let name = '.'+namearr[namearr.length-1]
                    _this.uploadZipImgList.push({data:file.content,name:name})
                    return
                }
                let reader = new FileReader()
                reader.readAsDataURL(file.file)

                reader.onload = function (e) {
                    let content= file.content //图片的src，base64格式
                    let img = new Image()
                    let namearr = file.file.name.split('.')
                    let name = '.'+namearr[namearr.length-1]
                    img.src = content
                    img.onload = function() {
                        console.log("压缩图片")
                        common.compress(img, (dataURL)=>{
                            _this.uploadZipImgList.push({data:dataURL,name:name})
                        })
                    }
                }
            },
            deletePic(file, pic) {
                this.imgArr.splice(pic.index,1)
                this.uploadZipImgList.splice(pic.index,1)
            },
        }
    }
</script>

<style lang="less">
    @import "../../style/putInto.less";
    .search-box{
        .van-cell{
            width: 85%;
        }
    }
    .imgUpload{
        background: #ffffff;
        padding-bottom: 20px;
    }
    .imgTitleDiv{
        padding: 16px 15px 16px 15px ;
        background: #fff;
        display: flex;
        align-items: center;
        overflow: hidden;
    }
    .imgDiv{
        padding: 0 15px;
    }

</style>
